import { PhoneNumberFormatHelper } from "@shared/helpers/PhoneNumberFormatHelper";
import { CreateOrEditCustomerDto, CreateOrEditGeneratorDto, CreateOrEditSiteDto, CustomerDto, GeneratorDto, SiteDto } from "@shared/service-proxies/service-proxies";

export type contactDTO = (SiteDto | GeneratorDto | CustomerDto);
export enum ContactType {
    Site = "Site",
    Customer = "Customer",
    Generator = "Generator"
}
export class Contact {

    name: string | undefined;
    displayName: string | undefined;
    id: number | undefined;
    type: ContactType;
    contactName: string | undefined;
    contactPhone: string | undefined;
    contactPhoneExtention: string | undefined;
    displayNumber: string | undefined;
    streetAddress: string | undefined;
    streetAddress2: string | undefined;
    city: string | undefined;
    state: string | undefined;
    zipCode: string | undefined;
    county: string | undefined;
    epaIdNumber: string | undefined;
    emergencyResponseNumber: string | undefined;
    termNetDays: number | undefined;
    enableCustomRecipients?: boolean;
    emailInstructions: string | undefined;
    emailRecipients?: string[] | undefined;
    emailCcRecipients?: string[] | undefined;

    private constructor(data: any, type: ContactType, id: number | undefined) {
        this.id = id;
        this.type = type;

        this.name = data.name;
        this.displayName = data.displayName;
        this.contactName = data.contactName;
        this.contactPhone = data.contactPhone;
        this.contactPhoneExtention = data.contactPhoneExtention;
        this.streetAddress = data.streetAddress;
        this.streetAddress2 = data.streetAddress2;
        this.city = data.city;
        this.state = data.state;
        this.zipCode = data.zipCode;
        this.county = data.county ?? null;
        this.epaIdNumber = data.epaIdNumber;
        this.emergencyResponseNumber = data.emergencyResponseNumber;
        this.termNetDays = data.termNetDays;
        this.enableCustomRecipients = data?.enableCustomRecipients ?? false;
        this.emailInstructions = data?.emailInstructions ?? null;
        this.emailRecipients = data?.emailRecipients ?? [];
        this.emailCcRecipients = data?.emailCcRecipients ?? [];
        this.updateDisplayNumber();
    }

    //#region Constructors
    public static fromJsonString(data: string | undefined, type: ContactType, id: number | undefined): Contact {
        let obj = {};
        if (data != null)
            obj = JSON.parse(data);
        return new Contact(obj, type, id);
    }

    public static fromDTO(data: contactDTO, type: ContactType, useName: boolean = false): Contact {
        data ?? {};
        const dto = new CreateOrEditSiteDto();
        dto.init(data);
        const split = PhoneNumberFormatHelper.splitPhoneNumber(data.contactPhone);
        dto.contactPhone = split.number;
        dto.contactPhoneExtention = split.ext;

        switch (type) {
            case (ContactType.Customer):
                (dto as unknown as CustomerDto).enableCustomRecipients = (data as CustomerDto).enableCustomRecipients;
                (dto as unknown as CustomerDto).emailRecipients = (data as CustomerDto).emailRecipients;
                (dto as unknown as CustomerDto).emailCcRecipients = (data as CustomerDto).emailCcRecipients;
                if (!useName)
                    (dto as unknown as CustomerDto).contactName = null;
                break;
            case (ContactType.Generator):
                (dto as unknown as GeneratorDto).emergencyResponseNumber = (data as GeneratorDto).emergencyResponseNumber;
                break;
            case (ContactType.Site):
                (dto as unknown as SiteDto).epaIdNumber = (data as SiteDto).epaIdNumber;
                break;
            default:
                break;
        }

        return new Contact(dto, type, data.id);
    }

    public static empty(type: ContactType): Contact {
        return new Contact({}, type, undefined);
    }
    //#endregion

    public setNameValues(data: contactDTO): void {
        if (data == null) return;
        this.name = data.name;
        this.displayName = data.displayName;
    }

    public updateDisplayNumber(): void {
        this.displayNumber = PhoneNumberFormatHelper.formatPhoneNumber(this.contactPhone, this.contactPhoneExtention);
    }

    public nameValuesAreNotSet(): boolean {
        return this.id != null && (this.name == null && this.displayName == null);
    }

    public copy(): Contact {
        return new Contact(this, this.type, this.id);
    }

    public isEmpty(): boolean {
        if (this.id != null)
            return false;

        return (this.name == null &&
            this.displayName == null &&
            this.contactName == null &&
            this.contactPhone == null &&
            this.streetAddress == null &&
            this.streetAddress2 == null &&
            this.city == null &&
            this.state == null &&
            this.zipCode == null &&
            this.county == null &&
            this.epaIdNumber == null &&
            this.emergencyResponseNumber == null &&
            this.termNetDays == null)
    }

    public isValid(): boolean {
        if (this.name == null)
            return false;

        if (this.type == ContactType.Customer && (!this.contactName))
            return false;

        if (this.type == ContactType.Site && (this.state == 'CA' && !this.county))
            return false;

        return true;
    }


    public toCreateOrEditSiteDto(): CreateOrEditSiteDto {
        const dto = new CreateOrEditSiteDto();
        dto.init(this);
        return dto;
    }

    public toCreateOrEditCustomerDto(): CreateOrEditCustomerDto {
        const dto = new CreateOrEditCustomerDto();
        dto.init(this);
        return dto;
    }

    public toCreateOrEditGeneratorDto(): CreateOrEditGeneratorDto {
        const dto = new CreateOrEditGeneratorDto();
        dto.init(this);
        return dto;
    }

    public toContactDTO(): contactDTO {
        switch (this.type) {
            case ContactType.Site:
                const site = new SiteDto();
                site.init(this);
                return site;
            case ContactType.Customer:
                const customer = new CustomerDto();
                customer.init(this);
                return customer;
            case ContactType.Generator:
                const gen = new GeneratorDto();
                gen.init(this);
                return gen;
            default:
                return null;
        }
    }
}
