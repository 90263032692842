import {PermissionCheckerService} from 'abp-ng2-module';
import {AppSessionService} from '@shared/common/session/app-session.service';

import {Injectable} from '@angular/core';
import {AppMenu} from './app-menu';
import {AppMenuItem} from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            // new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'flaticon-app', '/app/admin/editions'),

            new AppMenuItem('Projects', 'Pages.Projects', 'flaticon-interface-11', '/app/main/projects/projects'),

            new AppMenuItem('Contacts', '', 'flaticon-profile-1', '', [], [
                new AppMenuItem('Sites', 'Pages.Sites', '', '/app/main/sites/sites'),
                new AppMenuItem('Customers', 'Pages.Customers', '', '/app/main/customers/customers'),
                new AppMenuItem('Generators', 'Pages.Generators', '', '/app/main/generators/generators')
            ]),
            new AppMenuItem('Vendors', '', 'flaticon-share', '', [], [
                new AppMenuItem('Vendors', 'Pages.Vendors.Edit', '', '/app/main/vendors/vendors'),
                new AppMenuItem('TSDFs', 'Pages.Tsdfs.Edit', '', '/app/main/tsdfs/tsdfs'),
                new AppMenuItem('Add Invoice', 'Pages.Vendors.VendorInvoices.Create', '', '/app/main/vendors/add-invoice'),
                new AppMenuItem('Pending Invoices', 'Pages.Vendors.VendorInvoices.ViewPending', '', '/app/main/vendors/vendor-invoice-pending'),
                new AppMenuItem('Approved Invoices', 'Pages.Vendors.VendorInvoices.ViewApproved', '', '/app/main/vendors/vendor-invoice-approved')
            ]),
            new AppMenuItem('Lookups', '', 'flaticon-list', '', [], [
                new AppMenuItem('Job Statuses', 'Pages.JobStatuses.Edit', '', '/app/main/jobStatuses/jobStatuses'),
                new AppMenuItem('Task Groups', 'Pages.TaskGroups.Edit', '', '/app/main/taskGroups/taskGroups'),
                new AppMenuItem('Task Scopes', 'Pages.TaskScopes.Edit', '', '/app/main/taskScopes/taskScopes'),
                new AppMenuItem('Task Services', 'Pages.TaskServices.Edit', '', '/app/main/taskServices/taskServices'),
                new AppMenuItem('Task Types', 'Pages.TaskTypes.Edit', '', '/app/main/taskTypes/taskTypes'),
                new AppMenuItem('Waste Types', 'Pages.WasteTypes.Edit', '', '/app/main/wasteTypes/wasteTypes'),
                new AppMenuItem('Units', 'Pages.Units.Edit', '', '/app/main/units/units'),
                new AppMenuItem('State Waste Codes', 'Pages.StateWasteCodes.Edit', '', '/app/main/stateWasteCodes/stateWasteCodes'),
                new AppMenuItem('EPA Waste Codes', 'Pages.EpaWasteCodes.Edit', '', '/app/main/epaWasteCodes/epaWasteCodes'),
                new AppMenuItem('DOT PSNs', 'Pages.DotPsns.Edit', '', '/app/main/dotPsns/dotPsns'),
                new AppMenuItem('Container Types', 'Pages.ContainerTypes.Edit', '', '/app/main/containerTypes/containerTypes'),
                new AppMenuItem('Weight/Volume Units', 'Pages.WeightVolumeUnits.Edit', '', '/app/main/weightVolumeUnits/weightVolumeUnits'),
            ]),



             new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [], [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages', ['/app/admin/languages/{name}/texts']),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
                new AppMenuItem('DynamicProperties', 'Pages.Administration.DynamicProperties', 'flaticon-interface-8', '/app/admin/dynamic-property'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
            ])
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive = this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
