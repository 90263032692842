import { Pipe, PipeTransform } from '@angular/core';

/// <summary>
/// Adds space between capital case words.
/// </summary>
@Pipe({
    name: 'capitalizeSpacing'
})
export class CapitalizeSpacingPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return '';

        return value.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
}
